.similar-articles {

  &__header{
    @include fluid-type(12px, 12px);
    font-family: $_site-font-header;
    font-weight: normal;
    letter-spacing: 5px;    
    text-transform: uppercase;
  }

  &__tag {
    font-family: $_site-font-header;    
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;    
  }  

  & a {
    font-family: $_site-font-header;   
    font-size: 14px;
    line-height: 1.4;
    text-decoration: none;
    color: #000;
    display: block;
    &:hover {
      color: $_color-bright-magenta;
    }
  }
  & img {
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    max-height: 60px;
    min-height: 60px;
    max-width: 60px;
    min-width: 60px;
    border-radius: 50%;

    @include media-breakpoint-down(lg) {
      max-height: 50px;
      min-height: 50px;
      max-width: 50px;
      min-width: 50px;
    }
    @include media-breakpoint-down(md) {
      max-height: 80px;
      min-height: 80px;
      max-width: 80px;
      min-width: 80px;
    }
  }

}
