// /**
//     Placeholder
// */

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #231f20;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #231f20;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #231f20;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #231f20;
}

input,
textarea,
select {
    /*font-size: 16px !important;*/
    @include fluid-type(16px, 19px);        
    @include media-breakpoint-up(md) {
        //@include font-size(16); //, !important); //font-size:19px;

        @include fluid-type(16px, 19px);        
    }
}

select{
    line-height: 2.4 !important;    
}

/**
    General
*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="date"],
select {
    min-height: 54px;
}

.form-control {
    border-radius: 0px;
    @include fluid-type(16px, 19px);
    border-radius: 8px;
}

.input-label {
    @include fluid-type(16px, 20px);
    font-family: $_site-font-header;
    text-transform: uppercase;
}

// .text-label {
//     font-family: $_site-font-body-font;
//     display:block;

//     &--small {
//         @include fluid-type(14px, 14px);
//     }
// }

// .validation-summary-errors, .alert-success, .alert-error {
//     color: $_color-white;
//     padding: 20px 20px 10px 20px;
//     position: relative;
//     border: 5px solid $_color-pueblo;
//     position: relative;
//     margin-bottom: 20px;

//     &:before {
//         content: "";
//         position: absolute;
//         width: calc(100% - 10px);
//         height: calc(100% - 10px);
//         top: 5px;
//         left: 5px;
//         background-color: $_color-pueblo;
//         z-index: 1;
//     }

//     ul {
//         position: relative;
//         z-index: 2;
//     }
// }

// .alert-success {
//     border: 5px solid $_color-steel-blue;

//     ul {
//         padding-left: 10px;
//         margin-bottom: 20px;
//     }

//     li {
//         list-style-type: none;
//     }

//     &:before {
//         background-color: $_color-steel-blue;
//     }
// }

// .input-validation-error {
//     /*background-color: #fff7f6;*/
//     border-color: $_color-pueblo;
// }
// /**
//     Checkbox
// */
// .bishop-checkbox {
//     position: relative;
//     padding-left: 25px;
//     cursor: pointer;

//     span {
//         border: 1px solid transparent;
//         padding: 0 2px 0 2px;
//         position: relative;
//         top: 1px;
//     }

//     input[type=checkbox] {
//         position: absolute;
//         left: 2px;
//         top: 7px;
//     }

//     &:before {
//         content: "";
//         position: absolute;
//         width: 20px;
//         top: 4px;
//         left: 0px;
//         height: 20px;
//         background-color: $_color-white;
//         border-radius: 4px;
//         z-index: 1;
//         box-shadow: inset 0 1px 3px 0 rgba(43, 82, 111, 0.4);
//         transition: 0.2s;
//     }

//     &.is-checked {

//         &:before {
//             background-color: $_color-white;
//         }

//         &:after {
//             @include icomoon($_icon-check);
//             position: absolute;
//             color: $_color-teak;
//             @include font-size(15);
//             top: 3px;
//             left: 2px;
//             z-index: 2;
//         }
//     }

//     &.is-disabled {

//         &:before {
//             background-color: $_color-mercury;
//             border: solid 2px $_color-mercury;
//             box-shadow: none;
//             border-radius: 0;
//         }
//     }
// }
// /**
//     Radio
// */
// .bishop-radio {
//     position: relative;
//     padding-left: 25px;
//     cursor: pointer;

//     span {
//         border: 1px solid transparent;
//         padding: 0 2px 0 2px;
//     }

//     input[type=radio] {
//         position: absolute;
//         left: 4px;
//         top: 8px;
//     }

//     &:before {
//         content: "";
//         position: absolute;
//         width: 20px;
//         top: 4px;
//         left: 0px;
//         height: 20px;
//         background-color: $_color-white;
//         border-radius: 15px;
//         z-index: 1;
//         border: solid 2px #d0d0d0;
//         transition: 0.2s;
//     }

//     &.is-selected {

//         &:before {
//             background-color: $_color-teak;
//             border: solid 2px $_color-teak;
//         }

//         &:after {
//             content: "";
//             position: absolute;
//             background-color: $_color-white;
//             @include font-size(18);
//             border-radius: 10px;
//             width: 8px;
//             height: 8px;
//             top: 10px;
//             left: 6px;
//             z-index: 2;
//         }
//     }

//     &.is-disabled {
//         &:before {
//             background-color: #e5e5e5;
//             border: solid 2px #e5e5e5;
//             box-shadow: none;
//         }
//     }
// }
// /**
//     Select
// */
// .bishop-select {
//     position: relative;
//     background-color: $_color-white;
//     border-radius: 0px;

//     select {
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         text-indent: 1px;
//         text-overflow: '';
//         background-color: transparent;
//         z-index: 1;
//         position: relative;
//         cursor: pointer;

//         &::-ms-expand {
//             display: none;
//         }
//     }

//     &:before {
//         @include icomoon($_icon-arrow-down);
//         position: absolute;
//         color: #a7a7a7;
//         @include font-size(14);
//         top: 17px;
//         right: 15px;
//         z-index: 0;
//     }

//     &--gold, &--black {
//         padding: 3px;
//         background-color: $_color-teak;
//         font-family: $_site-font-header;
//         @include fluid-type(16px, 22px);

//         &:before {
//             color: $_color-cape-cod;
//             @include font-size(19);
//         }

//         .form-control {
//             border: 0px;
//         }

//         select {
//             color: $_color-cape-cod;
//         }
//     }

//     &--gold {
//         &:hover, &:target, &:active, &:focus {
//             transition: all 0.2s ease;
//             background-color: $_color-teak-dark;
//         }
//     }

//     &--black {
//         background-color: transparent;
//         border: 2px solid $_color-eternity;
//         padding: 1px;

//         &:before {
//             color: $_color-eternity;
//         }

//         select {
//             color: $_color-eternity;
//         }

//         &:hover, &:target, &:active, &:focus {
//             background-color: $_color-white;
//             border: 2px solid $_color-eternity;
//             /*select {
//                 color: $_color-black;
//             }

//             &:before {
//                 color: $_color-black;
//             }*/
//         }
//     }

//     &--black-frame {
//         background-color: $_color-cape-cod;
//         border: 2px solid $_color-eternity;
//         padding: 1px;
//         font-family: $_site-font-header;
//         @include fluid-type(16px, 22px);

//         &:before {
//             color: $_color-teak;
//         }

//         select {
//             color: $_color-teak;
//             text-transform: capitalize
//         }
//     }
// }

// .input-icon {
//     color: #829eab !important;
//     float: right;
//     margin-top: -40px;
//     position: relative;
//     z-index: 2;
//     margin-right: 15px;

//     &-eye {
//         &:after {
//             //@include icomoon($icon-eye);
//         }
//     }

//     &-eye-blocked {
//         &:after {
//             //@include icomoon($icon-eye-blocked);
//         }
//     }
// }
