.widget {
  &-topic {
      
  }
  
  &-comment {
      border: 1px solid #dbeede;
      border-radius: 8px;
      overflow: hidden;
  }
}
