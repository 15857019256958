﻿.section {
  &-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-image: linear-gradient(to bottom, #07192b, #2d3f51);
    min-height: 600px;
    text-align: center;

    //color: $_color-white;

    @include media-breakpoint-up(lg) {
      min-height: 800px;
      text-align: left;
    }

    &__icon {
      position: absolute;
      top: 20px;
      left: 20px;

      transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;

      &:hover,
      &:active,
      &:focus {
        transform: scale(1.05);
      }
    }

    &-hero {
      margin-bottom: 100px;

      @include media-breakpoint-up(lg) {
        min-height: 500px;
      }

      h1 {
        font-weight: normal;
      }

      h2 {
        color: $_color-white;
        text-transform: uppercase;
        letter-spacing: 7.06px;
        @include fluid-type(12px, 16px);
      }

      &-cta {

        &-holder {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }

        position: absolute;
        background-color: #f1f8ef;
        padding: 30px 40px;
        width: calc(100% - 30px);
        color: $_color-black;
        bottom: -70px;
        left: 15px;

        @include media-breakpoint-up(lg) {
          width: 475px;
          right: 0;
          left: inherit;
        }

      }
    }
  }

  &-top-scrolldown {
    position: relative;

    @include media-breakpoint-down(lg) {
      height: 100vh;
    }

    &__arrow {
      position: absolute;
      bottom: -200px;
      left: calc(50% - 27px);
      transition: bottom ease-in-out .3s;

      &:hover,
      &:focus,
      &:active {

        bottom: -210px;
      }
    }

    &__icon {
      transition: transform 0.2s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.1);
      }
    }

    h2 {
      color: $_color-white;
      text-transform: uppercase;
      letter-spacing: 7.06px;
      @include fluid-type(12px, 16px);
    }

    h1 {
      @include fluid-type(48px, 70px);
    }
  }

  &-top-subpage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-image: linear-gradient(to bottom, #07192b, #2d3f51);

    padding-top: 65px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      min-height: 500px;
    }

    &--small {
      min-height: 230px;

      @include media-breakpoint-up(lg) {
        min-height: 360px;
      }
    }
  }

  &-middle {
    margin-top: 60px;

    &--bg-blue-gradient {
      background-image: linear-gradient(to bottom, #07192b, #2d3f51);

      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        color: $_color-white;
      }
    }

    &--bg-gray {
      background-color: #faf9f8;
    }

    &--bg-pink {
      background-color: $_color-very-light-pink;
    }

    &--bg-zanah {
      background-color: $_color-zanah;
    }

    &--bg-charcoal {
      background-color: $_color-pickled-bluewood;

      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      a {
        color: $_color-white;
      }
    }

    &--bg-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      @include media-breakpoint-down(lg) {
        background-image: none !important;
      }

      .block-image-text {
        @include media-breakpoint-up(lg) {
          max-width: 475px;
        }
      }
    }

    &--bg-cover {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
      background-position-x: 50%;
      background-position-y: 50%;
      min-height: 180px;

      @include media-breakpoint-only(md) {
        min-height: 300px;
      }

      @include media-breakpoint-up(lg) {
        min-height: 600px;
      }
    }
  }
}