/**
    Base
*/

html {
    font-size: 100%;
    scroll-behavior: smooth;
}

[v-cloak] {
  display: none;
}

// body {
//     background-color: $_color-merino;
//     margin-top: 50px;

//     @include media-breakpoint-up(xl) {
//         margin-top: 80px;
//     }

// }

hr {
    border: none;
    border-bottom: 2px solid $_color-hr;
}

.no-scroll{
  overflow-y:hidden;
}