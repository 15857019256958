$_color-white: #fff;
$_color-black: #202020;

$_color-breaker-bay: #5eaa94;
$_color-bright-magenta: #ff00ff;
$_color-very-light-pink: #fcdfd8;
$_color-pickled-bluewood: #2a3d45;
$_color-nandor: #013d19;
$_color-zanah: #DBEEDE;

$_color-button-primary: $_color-breaker-bay;
$_color-button-secondary: $_color-pickled-bluewood;

$_color-hr: #C9C9C9;

.site-color {
  &--white {
    color: $_color-white !important;
  }

  &--black {
    color: $_color-black !important;
  }
  
  &--pink {
    color: $_color-bright-magenta !important;
  }  
}

.site-bgcolor {
  &--black {
    background-color: $_color-black !important;
  }

  &--white {
    background-color: $_color-white !important;
  }

  &--pink {
    background-color: $_color-bright-magenta !important;
  }
}
