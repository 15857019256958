.btn {
  width: 100%;
  text-align: center;
  min-height: 54px;
  max-height: 54px;
  min-width: 125px;
  padding: 0 40px;
  line-height: 3.2;
  // text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;

  &:hover,
  &:target,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  &.disabled {
    cursor: pointer;
    pointer-events: none;
  }

  &--inline {
    @include media-breakpoint-up(lg) {
      width: inherit;
    }
  }
}

.btn-primary,
.btn-secondary {
  border: none;
  @include fixed-type(16);
  font-family: $_site-font-header;
  cursor: pointer;
  display: inline-flex;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.5s ease, border 0.5s ease;

  // &:hover, &:target, &:active, &:focus {
  //     transition: all 0.5s ease;
  // }
}

.btn-primary {
  color: $_color-white;
  background-color: $_color-button-primary;
  border: 2px solid $_color-button-primary;
  background-position: center;
  /*transition: background 0.5s;*/
  &:hover,
  &:target {
    color: $_color-white;
    border: 2px solid darken($_color-button-primary, 10%);
    background: darken($_color-button-primary, 10%)
      radial-gradient(circle, transparent 1%, darken($_color-button-primary, 10%) 1%) center/15000%;
  }

  // &:active {
  //     background-color: darken($_color-button-primary, 10%);
  //     background-size: 100%;
  //     transition: background 0s;
  // }

  // &:disabled, &.disabled {
  //     background-color: $_color-arrow-town;
  //     border: 2px solid $_color-arrow-town;

  //     &:hover, &:target, &:active, &:focus {
  //         background-color: $_color-arrow-town;
  //     }
  // }
}

.btn-secondary {
  color: $_color-white;
  background-color: $_color-button-secondary;
  border: 2px solid $_color-button-secondary;

  &:hover,
  &:target,
  &:active,
  &:focus {
    color: $_color-white;
    border: 2px solid darken($_color-button-secondary, 10%);
    background-color: darken($_color-button-secondary, 10%);
  }

  //     &:disabled, &.disabled {
  //         background-color: $_color-arrow-town;
  //         border: 2px solid $_color-arrow-town;

  //         &:hover, &:target, &:active, &:focus {
  //             background-color: $_color-arrow-town;
  //         }
  //     }

  //     &--no-fill {
  //         background-color: transparent;
  //         border: 2px solid $_color-teak;
  //         color: $_color-teak;

  //         &:hover, &:target, &:active, &:focus {
  //             background-color: $_color-teak;
  //             border: 2px solid $_color-teak;
  //             /*color: $_color-white;*/
  //         }

  //         &:disabled, &.disabled {
  //             background-color: transparent;
  //             border: 2px solid $_color-arrow-town;
  //             color: $_color-arrow-town;

  //             &:hover, &:target, &:active, &:focus {
  //                 background-color: transparent;
  //                 color: $_color-arrow-town;
  //             }
  //         }
  //     }

  //     &--no-fill-black {
  //         background-color: transparent;
  //         border: 2px solid $_color-black;
  //         color: $_color-black;

  //         &:hover, &:target, &:active, &:focus {
  //             background-color: $_color-black;
  //             border: 2px solid $_color-black;
  //             color: $_color-white;

  //             &:before, &:after {
  //                 color: $_color-white;
  //             }
  //         }

  //         &:disabled, &.disabled {
  //             background-color: transparent;
  //             border: 2px solid $_color-arrow-town;
  //             color: $_color-arrow-town;

  //             &:hover, &:target, &:active, &:focus {
  //                 background-color: transparent;
  //                 color: $_color-arrow-town;
  //             }
  //         }
  //     }

  //     &--menu {

  //         @include media-breakpoint-only(xl) {
  //             padding: 0 30px;
  //             min-width: inherit;
  //         }

  //         @include media-breakpoint-down(lg) {
  //             position: relative;
  //             z-index: 1;
  //             padding: 8px 5px 0 5px;
  //             min-height: 50px;
  //             max-height: 50px;
  //             min-width: 50px;
  //             max-width: 50px;
  //             top: 5px;
  //             @include font-size(14);

  //             &:before {
  //                 content: "";
  //                 position: absolute;
  //                 top: 8px;
  //                 left: 5px;
  //                 width: 35px;
  //                 height: 2px;
  //                 background-color: $_color-cape-cod;
  //                 z-index: 2;
  //             }

  //             &:after {
  //                 content: "";
  //                 position: absolute;
  //                 bottom: 7px;
  //                 left: 5px;
  //                 width: 35px;
  //                 height: 2px;
  //                 background-color: $_color-cape-cod;
  //                 z-index: 2;
  //             }
  //         }
  //     }
}

// .btn-inverted {
//     color: $_color-white;
//     border: 2px solid $_color-white;
//     background-color: transparent;

//     &:hover, &:target, &:active, &:focus {
//         color: $_color-black;
//         background-color: $_color-white;
//         border: 2px solid $_color-white;
//     }

//     &:disabled, &.disabled {
//         background-color: transparent;
//         border: 2px solid $_color-arrow-town;
//         color: $_color-arrow-town;

//         &:hover, &:target, &:active, &:focus {
//             background-color: transparent;
//             color: $_color-arrow-town;
//         }
//     }
// }

// .btn-close {
//     background-color: $_color-cape-cod;
//     padding: 7px 15px 5px 15px;
//     /*@include font-size(28);*/
//     @include fluid-type(18px, 28px);
//     cursor: pointer;
//     display: inline-block;
//     border: 0;
//     /*@include media-breakpoint-down(lg) {

//     }*/
//     &:before {
//         @include icomoon($_icon-close);
//         color: $_color-white;
//     }
// }
