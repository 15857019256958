@use "sass:math";

// font size
@mixin fixed-type($sizeInPx: 20, $args...) {
    font-size: $sizeInPx + "px" + args-joined($args);
    //font-size: ($sizeInPx/16) + rem + args-joined($args);
    font-size: math.div($sizeInPx, 16) + rem + args-joined($args);
}


@function args-joined($args) {
  $result: "";

  @each $a in $args {
      $result: $result + " " + $a;
  }

  @return $result;  
}



// fluid font size

@function strip-unit($value) {
    //@return $value / ($value * 0+1);
    @return math.div($value, ($value * 0+1))
}



@mixin fluid-type($min-font-size, $max-font-size) {
    $min-vw: 320px;
    $max-vw: 1440px;

    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

// spacing classes
@mixin list-loop($className, $styleName) {
    $i: 0;

    @while $i <= 20 {
        #{$className + $i} {
            #{$styleName}: #{$i + 'px !important'};
        }

        $i: $i + 5;
    }
}

// get icon specific tags
@mixin icomoon($contentIn) {
    content: $contentIn;
    font-family: Icomoon;
}


// get icon specific tags
@mixin fa($contentIn) {
    content: $contentIn;
    font-family: FontAwesome;
}
