.svg {
  &-holder {
    position: relative;

    &--timeline-start {
      display: none;
      position: absolute;
      bottom: 0;
      right: 60px;
      width: 700px;
      height: 300px;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &--timeline-middle {
      display: none;

      position: absolute;
      right: 60px;
      // bottom: -31%;
      top: 0;
      width: 100px;
      height: 2000px;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &--timeline-end {
      display: none;
      position: absolute;
      right: 60px;
      bottom: 100px;
      width: 100px;
      height: 2000px;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-timeline {
    &-start {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 700px;
      height: 300px;
    }

    &-line {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 2000px;
    }
  }
}
