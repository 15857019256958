.main {
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        > .container.no-padding {
            padding-left: 0;
            overflow-x: hidden;
            padding-right: 0;
        }
    }

    &-wrapper {
        @include media-breakpoint-up(lg) {
            display: flex;
            min-height: 100%;
        }
    }
    
}
