 $_zindex-header: 1030;
 $_zindex-top-nav: 1060;
 $_zindex-top-nav-sidebar: 1050;

// $_zindex-mobile-bg: 5;
// $_zindex-inpage-nav: 4;
// $_zindex-mobile-search: 4;
// $_zindex-show-salary: 3;
// $_zindex-mobile-logo: 5;
 $_zindex-footer: 10;
// $_zindex-show-salary: 11;
// $_zindex-browser-not-supported: 1000041;
// $_zindex-booking-button: 1050;
// $_zindex-cookie-consent: 1000051;

