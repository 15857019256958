.top-nav {
  position: fixed;
  display: inline-block;
  cursor: pointer;
  z-index: $_zindex-top-nav;
  text-align: right;
  right: 0;
  top: 0;

  &-list {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: inline-block;
      position: relative;

      a,
      .top-nav-list-item {
        display: block;
        color: $_color-white;
        transition: background-color 0.3s ease;
        padding: 37px 9px 5px 9px;
        @include fixed-type(12);
        font-family: $_site-font-header;

        @include media-breakpoint-up(lg) {
          padding: 23px 25px 23px 25px;
          @include fixed-type(22);
        }

        @media (max-width: 320px) {
          padding: 37px 2px 5px 2px;
        }

        &:hover,
        &:active,
        &:focus {
          color: $_color-white;
        }

        &.is-menu {
          padding-left: 15px;
          text-decoration: none;

          @media (min-width: 320px) {
            // iphone 5
            padding-right: 10px;
          }

          @media (min-width: 321px) {
            // not iphone 5
            padding-right: 15px;
          }

          @include media-breakpoint-up(lg) {
            padding-right: 25px;
            padding-left: 55px;
          }
        }

        // &.is-active {
        //     background-color: lighten($_color-black, 15%);
        //     transition: background-color 0.3s ease;
        // }
      }
    }

    &__hamburger {
      position: absolute;
      top: 30px;
      left: 30px;
      transform: translate(-50%, -50%);
      width: 35px;
      height: 35px;

      @media (min-width: 321px) {
        // not iphone 5
        right: 15px;
      }

      @include media-breakpoint-up(lg) {
        // top: 23px;
        // left: 30px;
        right: inherit;
      }

      &:before,
      &:after {
        background: #fff;
        content: "";
        display: block;
        height: 4px;
        margin: 9px 0;
        transition: 0.5s;
      }

      &.is-open {
        &:before {
          transform: translateY(7px) rotate(135deg);
        }
        &:after {
          transform: translateY(-6px) rotate(-135deg);
        }
      }

      &-holder {
        position: absolute;
        left: inherit;
        right: 15px;
        top: 15px;
        //background-color: #081a2c;//$_color-bright-magenta;
        //background-image: linear-gradient(to bottom, #07192b, #2d3f51);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
          transform: scale(1.03);
          background-color: darken($_color-bright-magenta, 5%);
        }
      }
    }
  }

  &-sidebar {
    width: 100%;
    height: 100%;
    background-color: $_color-white;
    position: fixed;
    top: 0px;
    right: -520px;
    z-index: $_zindex-top-nav-sidebar;
    opacity: 0;
    // display: none;
    transition: 0.4s ease;
    box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.12);

    @include media-breakpoint-up(md) {
      width: 520px;
    }

    // @include media-breakpoint-up(lg) {
    //     top: 80px;
    // }

    &.is-active {
      // display: block;
      right: 0;
      opacity: 1;

      .top-nav-sidebar-list li {
        a {
          animation: fadeIn 1s ease;
          animation-fill-mode: both;
          //transform: scale(1);
          //transition: 1s;
        }

        &:before {
          // transition: 1s;
          // left: 0;

          animation: fadeInLine 1s ease;
          animation-fill-mode: both;
        }
      }
    }

    &-header-list {
      padding: 0;
      list-style-type: none;
      padding: 0 15px 10px 5px;
      margin: 10px 0;
      margin-bottom: 0;
      border-bottom: 1px solid #b0c7ba;

      @include media-breakpoint-up(md) {
        padding: 0 15px 10px 5px;
      }

      li {
        display: inline-block;
        @include fixed-type(16);
        font-weight: bold;
        // margin-left: 20px;
        color: $_color-black;
        opacity: 0.4;
        transition: opacity 0.3s;
        //  background-color: pink;
        display: inline-flex;
        align-items: center;       

        a {
          padding: 0 25px;
          padding-left: 0;
          text-decoration: none;
          display: flex;
          align-items: center;
          min-height: 80px;

          &:first-child {
            padding-left: 25px;
          }
  
          @include media-breakpoint-up(md) {
            @include fixed-type(18);
            padding: 0 25px;
            min-height: 80px;
  
            &:first-child {
              padding-left: 25px;
            }
          }
        }

        &.is-active {
          opacity: 1;
        }

        &:hover,
        &:active,
        &:focus {
          opacity: 1;
        }
      }
    }

    &-list {
      margin: 0;
      padding: 0;
      padding-top: 0px;
      padding-bottom: 30px;

      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100vh - 111px);

      li {
        // animation: fadeIn 0.8s ease;
        // animation-fill-mode: both;

        list-style: none;
        position: relative;
        // padding: 10px 0;
        transition: background-color 0.8s; //, transform 0.8s;
        display: block;
        //transform: scale(0.8);

        &:before {
          content: "";
          position: absolute;
          height: 1px;
          bottom: 0;
          width: 100%;
          //left: -1040px;
          background-color: #a5b1aa;
          transition: 1s;
        }

        &:last-child {
          &:before {
            background-color: #fff;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background-color: lighten(#a5b1aa, 25%);
          //transform: scale(1.02);
        }

        &.is-active {
          background-color: lighten(#a5b1aa, 25%) !important;
        }

        h2{
          @include fluid-type(16px, 24px);
        }
        h3{
          @include fluid-type(14px, 20px);
        }
      }

      &__link {
        padding: 30px 30px 30px 30px;
        display: block;
        color: $_color-white;
        @include fixed-type(22);
        font-family: $_site-font-header;
        text-decoration: none !important;
        position: relative;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include media-breakpoint-down(sm) {
          padding: 10px 20px 10px 20px;
        }

        // &:hover,
        // &:active,
        // &:focus {
        //     color: $_color-black;
        //     background-color: pink;
        // }

        // p, h5{
        //     text-decoration: none !important;
        // }

        img {
          margin-right: 30px;

          @include media-breakpoint-down(sm) {
            width: 40%;
            height: auto;
            margin-right: 15px;
          }
        }

        &-overlay {
          /* used for not triggerering fullpagejs on scroll */
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      // &-btn {
      //     margin-top: 40px;
      //     background: transparent;
      //     border: 0;
      //     @include font-size(20);

      //     &:before {
      //         position: relative;
      //         top: 2px;
      //         padding-right: 10px;
      //         @include icomoon($icon-close);
      //     }
      // }
    }

    &-enter-active,
    &-leave-active {
      transition: all 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
      transform: translateX(80px);
      opacity: 0;
    }
  }
}

$total-items: 5;

// Set delay per List Item
@for $i from 1 through $total-items {
  .top-nav-sidebar-list li:nth-child(#{$i}) a {
    animation-delay: 0.25s * $i !important;
  }
}

// Set delay per List Item
@for $i from 1 through $total-items {
  .top-nav-sidebar-list li:nth-child(#{$i})::before {
    animation-delay: 0.3s * $i !important;
  }
}

// Keyframe animation
@keyframes fadeIn {
  0% {
    opacity: 0;
    //top: 100px;
    transform: scale(0.9);
  }
  50% {
    opacity: 0.5;
  }
  75% {
    //top: 0px;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// Keyframe animation
@keyframes fadeInLine {
  0% {
    left: -520px;
  }
  50% {
  }
  75% {
  }
  100% {
    left: 0;
  }
}
