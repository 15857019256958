.figure {

  &-image-text {

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin: auto;
    }    

    figure {
      position: relative;
      text-align: center;
      background-color: rgba(65, 46, 128, 0.05);
      padding: 40px 30px 94px 30px;
      margin-top: 20px;

      @include media-breakpoint-up(lg) {
        flex-basis: calc(33.33% - 20px);
        box-sizing: border-box;
        min-height: 375px;        
      }      
    }

    figcaption {
      text-align: center;

    }

    a {
      position: absolute;
      bottom: 20px;
      display: inline-block;
      left: 30px;
      width: calc(100% - 60px);
    }

  }

}