.list {
  &-image {
    // margin-top: 40px;

    // @include media-breakpoint-up(lg) {
    //   margin-top: 60px;
    // }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    &__item {
      position: relative;
      margin-top: 40px;

      // &:first-child{
      //   margin-top: 0;
      // }

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-text {
        @include media-breakpoint-up(lg) {
          padding-left: 50px;
        }
      }

      &-image {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
          text-align: left;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &--right {
        flex-direction: row-reverse;

        .list-image__item-text {
          @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 50px;
          }
        }
      }
    }

    &--right {
      .list-image__item {
        flex-direction: row-reverse;

        &-text {
          @include media-breakpoint-up(lg) {
            padding-right: 30px;
            padding-left: 0;
          }
        }
      }
    }

    &--block {
      ul {
        width: 100%;
        margin: auto;
        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        li {
          margin-bottom: 20px;
          @include media-breakpoint-up(md) {
            flex-basis: calc(50% - 15px);
            align-items: flex-start;
            flex-direction: column;
          }
        }

        .list-image__item-image {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }

        .list-image__item-text {
          padding-left: 0;
        }
      }
    }

    &--img-large {
      img {
        width: 200px;
        height: 200px;
      }
    }

    &--img-xlarge {
      img {
        width: 350px;
        height: 350px;
      }
    }
  }

  &-footer {
    margin: 0;
    padding: 0;
    margin-bottom: 40px;

    li {
      list-style-type: none;
      margin-top: 1px;
      // text-align: center;

      // @include media-breakpoint-up(lg) {
      //   text-align: left;
      // }

      a {
        color: $_color-white;
        text-decoration: none;
        @include fluid-type(14px, 16px);
        font-weight: normal;
      }
    }

    &--column-count2 {
      // column-count: 2;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        //:nth-of-type(odd){
        // flex: 1 300px;
        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }
    }
  }

  &-number {
    ul {
      counter-reset: my-awesome-counter;
      list-style: none;
      padding-left: 40px;
      margin-bottom: 20px !important;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: auto;
      }
    }

    li {
      margin: 35px 0 0 35px;
      counter-increment: my-awesome-counter;
      position: relative;

      @include media-breakpoint-up(md) {
        margin: 35px 0 0 60px;
      }

      @include media-breakpoint-up(lg) {
        flex-basis: calc(50% - 120px);

        // align-items: flex-start;
        // flex-direction: column;
      }
    }

    li::before {
      content: "0" counter(my-awesome-counter);
      color: $_color-black;
      @include font-size(64px);
      position: absolute;
      --size: 28px;
      left: calc(-1 * var(--size) - 35px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: 15px;

      @include media-breakpoint-up(md) {
        left: calc(-1 * var(--size) - 60px);
      }

      @include media-breakpoint-up(lg) {
        top: 40px;
      }
    }
  }
}
