body {
    box-sizing: border-box;
}

.site-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;//min-height: calc(100vh - 80px);
}

.main {
    flex-grow: 1;
}
