.pagination {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      a {
        background-color: darken($_color-breaker-bay, 10%);
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 10px 15px;
      min-height: 40px;
      min-width: 40px;
      padding: 0 15px;
      background-color: $_color-breaker-bay;
      line-height: 0.8;

      @include fluid-type(14px, 14px);
      font-family: $_site-font-header;
      font-weight: normal;
      letter-spacing: 2px;
      color: $_color-white;
      text-decoration: none;
      transition: background-color .3s;

      &:hover, &:focus, &:target{
        background-color: darken($_color-breaker-bay, 10%);
      }
    }
  }
}
