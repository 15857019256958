/**

    General font settings

*/

$_site-font-header: 'Arial', sans-serif;
$_site-font-body-font: 'Arial', sans-serif;
$_site-font-body: 'Arial', sans-serif;

// @font-face {
//     font-family: 'FranklinGothicLTPro-CnDm';
//     src: url('/wwwroot/fonts/3AB6C2_0_0.eot');
//     src: url('/wwwroot/fonts/3AB6C2_0_0.eot?#iefix') format('embedded-opentype'),url('/wwwroot/fonts/3AB6C2_0_0.woff2') format('woff2'),url('/wwwroot/fonts/3AB6C2_0_0.woff') format('woff'),url('/wwwroot/fonts/3AB6C2_0_0.ttf') format('truetype');
// }

// @font-face {
//     font-family: 'FranklinGothicLTPro-Md';
//     src: url('/wwwroot/fonts/3AB6C2_1_0.eot');
//     src: url('/wwwroot/fonts/3AB6C2_1_0.eot?#iefix') format('embedded-opentype'),url('/wwwroot/fonts/3AB6C2_1_0.woff2') format('woff2'),url('/wwwroot/fonts/3AB6C2_1_0.woff') format('woff'),url('/wwwroot/fonts/3AB6C2_1_0.ttf') format('truetype');
// }

