.blog {
  &-item {
    background-color: $_color-white;
    img {
      max-height: 360px;
      min-height: 360px;
      width: 100%;
      object-fit: cover;
      transition: transform 0.2s ease-in-out;
      position: relative;

      &:hover,
      &:focus,
      &:target {
        transform: scale(1.02);
      }
    }

    h2 {
      a {
        text-decoration: none !important;
        display: block;
        // padding: 5px 15px 10px 15px;
      }
    }
    p {
      padding: 0 15px 10px 15px;
      margin-top: 0;
    }
  }

  &-category {
    display: inline-block;
    margin: 0 20px 20px 0;

    a {
      @include fluid-type(12px, 14px);
      font-family: $_site-font-header;
      font-weight: bold;
      padding: 10px;
      background-color: #f5f5f5;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 2px;

      &.active {
        background-color: $_color-bright-magenta;
        color: $_color-white;
      }
    }
  }

  &-date {
    position: relative;
    @include fluid-type(12px, 14px);
    letter-spacing: 2px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    min-height: 30px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -6px;
      height: 30px;
      border-left: 1px solid $_color-black;
    }
  }
}
