.slide {
    // display:flex;
    // justify-content: center;
    // align-items: center;

    padding: 0 10%; 

    // &-one {
    //     background-color: #b7debd;
    // }

    // &-two {
    //     background-color: #fff;
    // }

    &-three {
        background-color: #1b1919;
        p {
            color: #fff;
        }

        h2,
        h3 {
            color: #fff;
        }
    }

    // &-four {
    //     background-color: #fff;
    // }

    // &-five {
    //     background-color: #b7debd;
    // }
    // &-six {
    //     background-color: #fcdfd8;
    // }

    // &-seven {
    //     background-color: #b7debd;
    // } 

    // &-eight {
    //     background-color: #dbeede;
    // }     

    // &-nine {
    //     background-color: #fec813;
    // }         
}
