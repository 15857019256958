﻿.cms-content {
  ul {
    margin: 0;
    padding: 0;
    padding-left: 30px;
    margin-top: 10px;

    li {
      margin-bottom: 10px;
    }
  }

  a {
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline !important;
    }

    &.btn {
      text-decoration: none !important;
    }
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 35px;
  }

  img{
    max-width: 100%;
  }
}
