body {
  button:focus,
  button[type=submit]:focus,
  input[type=button]:focus,
  input[type=submit]:focus,
  input[type=password]:focus,
  input[type=text]:focus,
  input[type=search]:focus,
  input[type=email]:focus,
  select:focus,
  a:focus,
  textarea:focus {
      /*outline: #687682 dotted thin !important;*/
      outline: 2px dashed #687682 !important;
  }

  &.no-outline {

      a:focus,
      button:focus,
      button[type=submit]:focus,
      input[type=button]:focus,
      input[type=submit]:focus,
      input[type=password]:focus,
      input[type=text]:focus,
      input[type=search]:focus,
      input[type=email]:focus,
      select:focus,
      a:focus,
      textarea:focus {
          outline: none !important;
          outline: 0 !important;
      }
  }
}
