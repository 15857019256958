.section {
  &-top {
    background-image: linear-gradient(to bottom, #07192b, #2d3f51);
    color: $_color-white;
  }
}

.footer {
  background-color: $_color-black;
  color: $_color-white;

  min-height: 100px;
  padding-bottom: 40px;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }
}

.top-nav {
  &-list {
    &__hamburger {
      &-holder {
        background-color: #081a2c; //$_color-bright-magenta;
      }
    }
  }
}
